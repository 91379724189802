<template>
  <v-dialog v-model="showAzvScanModal" width="800" scrollable persistent>
    <v-card class="received-form" v-if="user">
      <v-card-title class="headline grey lighten-3 mb-4 grey--text text--darken-1">Scan AZV barcode </v-card-title>
      <v-card-text class="pt-2 pb-8">
        <v-alert text icon="mdi-barcode-scan" prominent type="info"> <h3>Please scan user's AZV barcode on AZV card or App</h3></v-alert>

        <v-row justify="center">
          <v-alert color="error" dark v-if="!user.azvNumber" dense class="body-2 mt-1 mb-1">
            <v-icon left> mdi-alert-circle</v-icon> User has no AZV number registered and cannot continue without one.
          </v-alert>
          <v-col sm="8">
            <v-form v-on:submit.prevent="scanAzvNumber(scannedInput)" autocomplete="off">
              <v-text-field
                ref="scanAzvCard"
                autocomplete="off"
                id="scanAzvCard"
                outlined
                :hint="hint"
                @focus="readyToScan = true"
                @blur="readyToScan = false"
                :rules="[readyToScan || disableScanInput ? true : 'Place cursor inside text field before scanning']"
                persistent-hint
                background-color="white"
                v-model="scannedInput"
                v-bind:value="scannedInput"
                append-icon="mdi-barcode-scan"
                :label="'Scan subject QR-code or test tube barcode'"
                single-line
                :disabled="scanInputLoading || disableScanInput"
                :loading="scanInputLoading"
                v-if="user.azvNumber"
              ></v-text-field>
            </v-form>
            <transition name="slide-bottom">
              <v-alert v-if="validationError" text icon="mdi-alert-circle" type="error"> {{ errorMessage }}</v-alert>
            </transition>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions class="py-4">
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="closeModal()" :disabled="submiting">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';

export default {
  mixins: [SharedFunctions],
  props: ['user', 'box', 'showAzvScanModal', 'submiting', 'location'],
  data() {
    return {
      scannedInput: null,
      scanInputLoading: false,
      readyToScan: false,
      validationError: false,
      loading: false,
      errorMessage: ''
    };
  },
  mounted() {
    this.focusInput();
  },
  methods: {
    closeModal() {
      this.$parent.showAzvScanModal = false;
    },
    async scanAzvNumber(input) {
      this.scannedInput = input.trim();
      //check if valid azv number
      if (!this.validateAzvNumber(this.scannedInput)) {
        this.validationError = true;
        this.errorMessage = 'AZV number not valid';
        this.focusInput();
        return;
      } else {
        this.validationError = false;
      }

      //check if exists in db
      this.scanInputLoading = true;
      const numberInDb = await this.azvNumberAlreadyRegistered(this.scannedInput);
      this.scanInputLoading = false;
      if (!numberInDb) {
        this.validationError = true;
        this.errorMessage = 'AZV number not found in system';
        this.focusInput();
        return;
      } else {
        this.validationError = false;
      }

      //check if matches saved azv number
      if (parseInt(this.scannedInput) === parseInt(this.user.azvNumber)) {
        //can continue
        this.$parent.showAzvScanModal = false;
        this.$parent.showConfirmModal = true;
      } else {
        this.validationError = true;
        this.errorMessage = 'AZV number does not match the registerd user';
        this.focusInput();
      }
    },
    focusInput() {
      setTimeout(() => document.getElementById('scanAzvCard').focus(), 100);
    }
  }
};
</script>


<style lang="scss" scoped>
.info-card {
  flex: 1 1 0;
  width: 0;
  .text {
    //padding: 0 20px;
  }
  .v-list-item {
    //border-bottom: 1px solid lighten(grey, 38%);
  }
}
</style>
