<template>
  <v-container class="tourist-scan-page" v-if="hasAccessToPage">
    <v-row justify="center">
      <v-col cols="12" lg="12" xl="9">
        <VaccineScanInput :disableScanInput="disableScanInput" :caseId="caseId" />
      </v-col>
    </v-row>
    <v-row class="mt-0" justify="center">
      <v-col cols="12" sm="12" md="12" lg="12" xl="9">
        <UserCheckinCards :users="users" :loadProgress="loadProgress" :userSelectedProp="userSelected" />
      </v-col>
    </v-row>

    <AzvScanModal v-if="showAzvScanModal" :showAzvScanModal="showAzvScanModal" :user="userSelected" />
    <CheckinConfirmModal
      v-if="showConfirmModal"
      :users="users"
      :user="userSelected"
      :location="locations[selectedLocation - 1]"
      :showConfirmModal="showConfirmModal"
      :box="false"
      @setCheckinData="setCheckinData"
    />
  </v-container>
  <NoAccessMessage v-else />
</template>

<script>
import UserCheckinCards from '@/views/VaccineViews/VaccineCheckinScanPage/components/UserCheckinCards.vue';
import VaccineScanInput from '@/views/VaccineViews/components/VaccineScanInput.vue';
import CheckinConfirmModal from '@/views/VaccineViews/VaccineCheckinScanPage/components/CheckinConfirmModal.vue';
import AzvScanModal from '@/views/VaccineViews/VaccineCheckinScanPage/components/AzvScanModal.vue';
import '@/assets/scss/visitor-scan-generic.scss';
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import NoAccessMessage from '@/components/generic/no-access-message.vue';
//import moment from 'moment';

export default {
  mixins: [SharedFunctions],
  components: {
    UserCheckinCards,
    VaccineScanInput,
    NoAccessMessage,
    CheckinConfirmModal,
    AzvScanModal
  },
  data() {
    return {
      userSelected: null,
      userSelectedCheckInData: [],
      users: [],
      selectedBox: null,
      intervalScanTime: 5000,
      loadProgress: null,
      scannedCaseId: null,
      disableScanInput: false,
      hasAccessToPage: false,
      intervalTimer: null,
      showConfirmModal: false,
      showAzvScanModal: false,
      submiting: false,
      caseId: this.$route.params.caseId ? this.$route.params.caseId : null
    };
  },
  computed: {
    selectedNurse() {
      return this.$store.state.vaccinePage.selectedNurse;
    },
    selectedLocation() {
      return parseInt(this.$store.state.vaccinePage.selectedCheckinLocation);
    },
    locations() {
      return this.$store.state.vaccine.locations;
    },
    saveButtonDisabled() {
      return this.userSelected && this.selectedLocation ? false : true;
    },
    locationSelectedMesg() {
      if (this.selectedLocation) return null;
      else return 'Please select a location';
    },
    vaccineDataPatientCase() {
      return this.$store.state.vaccine.vaccineDataPatientCase;
    }
  },
  created() {
    this.hasAccessToPage = this.hasAccessToPageMixin();
  },
  mounted() {
    this.focusScanInput();
  },
  beforeDestroy() {
    clearTimeout(this.intervalTimer);
    this.resetAllData();
  },
  methods: {
    displaySubjectData(subjectData, scannedCaseId) {
      //if scanned input passed, find it and check if it has vaccine, if so select it
      if (scannedCaseId) this.userSelected = subjectData.filter((subject) => subject.patientCaseId == scannedCaseId)[0];
      else this.userSelected = null;
      this.users = subjectData;
      this.scannedCaseId = scannedCaseId;
      this.focusScanInput();
      this.getCheckInDataUser(subjectData);
      //this.initIntervalScan(true);
    },
    selectUserCard(user) {
      this.userSelected = user;
      this.scannedCaseId = user.patientCaseId;
      this.testTubeMessage = null;
    },
    async getCheckInDataUser(users) {
      let i = 0;
      for await (const user of users) {
        const data = await this.$store.dispatch('getCheckInDataUser', user.patientCaseId);
        this.$set(this.users[i], 'checkInData', data);
        i++;
      }
    },
    focusScanInput() {
      setTimeout(() => document.getElementById('scanTextInput').focus(), 0);
    },
    resetAllData() {
      this.users = [];
      this.userSelected = null;
      this.testTubeKitInfo = null;
      this.disableScanInput = false;
      this.scannedCaseId = null;
    },
    resetDataAfterLinkCreated() {
      this.disableScanInput = false;
      this.focusScanInput();
    },
    setCheckinData(data) {
      this.$set(this.users[data.index], 'checkInData', data.checkinData);
    }
  }
};
</script>
