<template>
  <v-card class="elevation-1 card-empty visitor-card">
    <v-app-bar class="elevation-0 bg-whitesmoke">
      <v-icon color="primary">mdi-account-multiple</v-icon>
      <v-toolbar-title class="primary--text ms-2 text-uppercase subtitle-1 font-weight-medium">Users</v-toolbar-title>
    </v-app-bar>
    <div class="inputs-wrapper py-0 px-3 pt-1 pb-0">
      <v-row>
        <v-col cols="12" md="6">
          <v-select
            prepend-inner-icon="mdi-map-marker"
            v-model="selectedLocation"
            :loading="loadingLocations"
            @change="setLocation($event)"
            :disabled="loadingLocations"
            :items="vaccineLocations"
            item-value="id"
            item-text="name"
            label="Location"
            class="mt-3"
            outlined
            clearable
            background-color="#fff"
            dense
          >
            <template v-slot:item="data">
              <template>
                <v-list-item-content>
                  <v-list-item-title v-html="data.item.name"></v-list-item-title>
                  <v-list-item-subtitle v-html="data.item.address"></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </div>

    <v-card-text class="bg-white text-area user-card vaccine-user-card">
      <transition name="wizard-down">
        <v-row justify="center" v-if="users.length > 0" style="width: 100%">
          <v-col cols="12" sm="6" xl="4" class="mx-2" v-for="user in users" :key="user.patientCaseId">
            <!-- USER CARD -->
            <v-card
              @click="$parent.selectUserCard(user)"
              :ripple="false"
              :class="userSelected.patientCaseId == user.patientCaseId ? 'active-card' : ''"
              class="mb-4 relation-card pb-2 pt-0 elevation-0 cardwidth"
              outlined
            >
              <v-card-text class="pt-3">
                <div class="overline color" style="position: absolute; bottom: 5px; right: 8px">
                  <span class="">
                    {{ userSelected.patientCaseId == user.patientCaseId ? 'Selected' : '' }}
                  </span>
                </div>

                <!-- USER INFO -->
                <div class="d-flex flex-no-wrap justify-space-between">
                  <div>
                    <v-card-title class="pt-0 px-0" v-text="user.firstname + ' ' + user.lastname"></v-card-title>
                    <v-card-subtitle class="px-0 py-0 case-number">{{ user.name }}</v-card-subtitle>
                    <div>
                      <span class="label">Birthday: </span>
                      <span class="value">{{ user.birthDay ? formatDate(user.birthDay) : 'Not filled' }}</span>
                    </div>
                    <div>
                      <span class="label">Email: </span>
                      <span class="value">{{ user.email ? user.email : 'Not filled' }}</span>
                    </div>
                  </div>

                  <v-avatar class="ma-2 check-icon-wrapper" size="60" rounded>
                    <v-icon>mdi-account</v-icon>
                  </v-avatar>
                </div>

                <!-- WARININGS & ERRORS -->
                <div class="my-4">
                  <v-alert color="warning" v-if="!user.vaccine" dense class="body-2 mt-2 mb-2 white--text">
                    <v-icon left color="white"> mdi-alert-circle</v-icon> User has no appointment for a vaccine
                  </v-alert>
                  <v-alert color="error" dark v-if="!user.azvNumber" dense class="body-2 mt-1 mb-0" style="width: 100%" text>
                    <v-icon left color="error"> mdi-alert-circle</v-icon> User has no AZV number registered
                  </v-alert>
                </div>

                <!-- CHECKINS -->
                <transition name="slide-bottom">
                  <CheckinDataCard
                    :user="user"
                    parent="checkin"
                    v-if="userSelectedProp && userSelectedProp.vaccine && userSelectedProp.patientCaseId == user.patientCaseId"
                  />
                </transition>

                <!-- VACCINE INFO -->
                <transition name="slide-bottom">
                  <template v-if="userSelectedProp && userSelectedProp.vaccine && userSelectedProp.patientCaseId == user.patientCaseId">
                    <div class="vaccine-info mt-3">
                      <div>
                        <DosageCard v-for="vaccine in vaccineData" :key="vaccine.vaccineGroupedName"
                          :dosage="vaccine.vaccineDose"
                          :appointment="vaccine.appointment"
                          :vaccineNumber="vaccine.vaccineNr"
                          :vaccineDate="vaccine.vaccineDate"
                        />
                        
                      </div>
                    </div>
                  </template>
                </transition>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </transition>
      <transition name="wizard-down">
        <div v-if="users.length == 0" class="check-icon-wrapper icon-wrapper">
          <v-icon>mdi-account-multiple</v-icon>
        </div>
      </transition>
    </v-card-text>
  </v-card>
</template>

<script>
import SharedFunctions from '@/mixins/SharedFunctions.vue';
import DosageCard from '@/views/VaccineViews/components/DosageCard.vue';
import CheckinDataCard from '@/views/VaccineViews/components/CheckinDataCard.vue';

export default {
  mixins: [SharedFunctions],
  props: ['users', 'loadProgress', 'userSelectedProp', 'userSelectedCheckInData'],
  components: {
    DosageCard,
    CheckinDataCard
  },
  data() {
    return {
      loadingLocations: false,
      vaccineData: {}
    };
  },
  computed: {
    vaccineLocations() {
      return this.$store.state.vaccine.locations.filter((location) => location.dossageAllow);
    },
    selectedLocation() {
      return parseInt(this.$store.state.vaccinePage.selectedCheckinLocation);
    },
    userSelected() {
      if (this.userSelectedProp) return this.userSelectedProp;
      else {
        return {
          patientCaseId: null
        };
      }
    }
  },
  watch: {
    userSelectedProp(user) {
      this.vaccineData = this.groupVaccineData(user.vaccine);
    }
  },
  
  created() {
    if (this.vaccineLocations.length == 0) this.getVaccineLocations();
    else this.checkLocalStorage();
  },
  methods: {
    getVaccineLocations() {
      this.loadingLocations = true;
      this.$store.dispatch('getVaccineLocations').finally(() => {
        this.loadingLocations = false;
        this.checkLocalStorage();
      });
    },
    checkLocalStorage() {
      const location = this.getLocalStorageObject('dvg_vaccine_data', 'locationCheckin');
      if (location) this.setLocation(location);
      this.nurse = this.getLocalStorageObject('dvg_vaccine_data', 'nurse');
    },
    setLocation(location) {
      this.setLocalStorageObject('dvg_vaccine_data', 'locationCheckin', location);
      this.$store.commit('SET_VACCINE_SELECTED_CHECKIN_LOCATION', location);
    }
  }
};
</script>

<style lang="scss" >
.visitor-card {
  .v-card--link:focus:before {
    opacity: 0 !important;
  }
  .overline.color {
    color: #2196f3;
    font-weight: 600;
  }
  .vaccine-user-card {
    .label {
      color: darken(grey, 5%);
    }
    .value {
      font-weight: 400;
      color: darken(grey, 10%);
    }
    .check-icon-wrapper {
      background: whitesmoke;
      i {
        font-size: 2.5rem;
        color: lightgrey;
      }
    }
    .relation-card {
      border-radius: 10px !important;
      .title {
        font-size: 1.1rem !important;
        margin-bottom: 2px;
      }
      .case-number {
        font-size: 1rem;
        color: rgba(0, 0, 0, 0.87);
        margin-bottom: 4px;
      }
      &:hover {
        //background: rgba(#2196f3, .1) !important;
        box-shadow: 0px 3px 14px 0px rgba(0, 0, 0, 0.2) !important;
      }
    }
    .overline {
      font-size: 14px !important;
    }
    .active-card {
      border: 3px solid #2196f3 !important;
    }
    .cardwidth {
      min-width:410px !important;
    }
    .vaccine-info {
      .v-icon {
        color: lighten(grey, 20%);
        margin-right: 5px;
      }
    }
  }

  .inputs-wrapper {
    background: #f5f5f5;
    border-bottom: 1px solid darken(#f5f5f5, 10%);
  }
}
</style>
